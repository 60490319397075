import {Inject, Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {
    APIService, ContactType,
    CreateContactInput, ModelMembershipFilterInput,
    UpdateContactInput
} from '../../../API.service';
import {AuthService} from '../../auth/services/auth.service';
import {CONTACT_MANAGER_OPTIONS} from "./contact.options";
import {getDeepFromObject} from "../../auth/helpers";
import {Company} from "./org.model";

@Injectable()
export class CompanyService implements Resolve<any>
{

    routeParams: any;

    onContactChanged: BehaviorSubject<any>;
    onSearchTextChanged: Subject<any>;
    onFilterChanged: Subject<any>;
    nextPageToken: BehaviorSubject<any>;

    contacts: any[];
    currentContact: any;
    user: any;
    pageSize: number;
    nextToken: string;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     * @param options
     * @param api
     * @param _authService
     */
    constructor(
        @Inject(CONTACT_MANAGER_OPTIONS) protected options = {},
        private _httpClient: HttpClient,
        private api: APIService,
        private _authService: AuthService
    )
    {
        // Set the defaults
        this.onContactChanged = new BehaviorSubject({});
        this.onSearchTextChanged = new Subject();
        this.onFilterChanged = new Subject();
        this.nextToken = null;
        this.nextPageToken = new BehaviorSubject(this.nextToken);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        this.routeParams = route.params;
        return new Promise((resolve, reject) => {
            Promise.all([
                this.getContact(null),
            ]).then(
                ([contact]) => {

                    resolve();

                },
                reject
            );
        });
    }

    getContact(id: string): Promise<any> {
        const contactIdVal = id !== null ? id : this.routeParams.id;
        return new Promise((resolve, reject) => {
            this.api.GetContact(contactIdVal)
                .then(contact => {
                    if (contactIdVal === 'new') {
                        this.currentContact = new Company();
                    } else {
                        this.currentContact = contact;
                    }
                    const filter: ModelMembershipFilterInput = {
                        contactId: {eq: contactIdVal}
                    };
                    return this.api.ListMemberships(filter, 10);
                }).then(memberships => {
                    console.log(memberships);
                    this.currentContact.licence = memberships;
                    this.onContactChanged.next(this.currentContact);
                    resolve(this.currentContact);
                })
                .catch((err) => reject(err));
        });
    }

    save(data): Promise<any>  {
        return new Promise((resolve, reject) => {
            const payload: UpdateContactInput = {
                id: data.id,
                contactName: data.contactName,
                email: data.email,
                phone: data.phone,
                avatar: data.avatar,
                avatarType: data.avatarType,
                contactSince: data.contactSince,
                contactRelId: '.',
                status: data.status,
                updatedAt: new Date().toISOString(),
                _version: data._version
            };
            this.api.UpdateContact(payload)
                .then((contact) => {
                    this.onContactChanged.next(contact);
                    resolve(contact);
                }).catch((err) => {
                    console.log(err);
                    reject(err);
                });
        });
    }

    savePB(data): Promise<any>  {
        return new Promise((resolve, reject) => {
            const userInfo = this._authService.getUserId();
            const userId = userInfo.getUsername();
            const payload: UpdateContactInput = {
                id: data.id,
                userID: userId,
                notes: data.notes,
                businessId: data.businessId,
                updatedAt: new Date().toISOString(),
                _version: data._version
            };
            this.api.UpdateContact(payload)
             .then((contact) => {
                this.onContactChanged.next(contact);
                resolve(contact);
            }).catch((err) => {
                console.log(err);
                reject(err);
            });
        });
    }

    add(data) {
        return new Promise((resolve, reject) => {
            const userInfo = this._authService.getUserId();
            const userId = userInfo.getUsername();
            const payload: CreateContactInput = {
                userID: userId,
                contactName: data.contactName,
                email: data.email,
                phone: data.phone,
                avatar: data.avatar,
                avatarType: data.avatarType,
                contactType: ContactType.Organization,
                contactSince: data.contactSince,
                notes: 'Add notes',
                contactRelId: '.',
                starred: false,
                frequent: false,
                createdAt: new Date().toISOString(),
                updatedAt: new Date().toISOString(),
                status: data.status
            };
            this.api.CreateContact(payload)
                .then((contact) => {
                    this.onContactChanged.next(contact);
                    resolve(contact);
                }).catch((err) => {
                    console.log(err);
                    reject(err);
                });
        });
    }

    // Functions
    getConfigValue(key: string): any {
        return getDeepFromObject(this.options, key, null);
    }
}
