import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ReplaySubject, Subject} from "rxjs";
import {Location} from "@angular/common";
import {MatSnackBar} from "@angular/material/snack-bar";
import {takeUntil} from "rxjs/operators";
import * as _ from "lodash";
import {Company} from "../org.model";
import {fuseAnimations} from "../../../../../@fuse/animations";
import {CompanyService} from "../company.service";
import {parsePhoneNumberFromString} from "libphonenumber-js";

interface ContactStatus {
  value: string;
  name: string;
}

interface AvatarType {
  value: string;
  name: string;
}

@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations   : fuseAnimations
})
export class OrganizationComponent implements OnInit, OnDestroy
{
  company: Company;
  pageType: string;
  companyForm: FormGroup;
  pbForm: FormGroup;

  base64Img: any;

  showOnAvatar: string;
  showOnRelOther: boolean;

  avatarOptions: AvatarType[] = [
    { value: 'ni', name: 'Name Initials'},
    { value: 'gd', name: 'Email'},
    { value: 'img', name: 'Image File'}
  ];

  statusOptions: ContactStatus[] = [
    { value: 'active', name: 'Active'},
    { value: 'inactive', name: 'Inactive'},
  ];


  customAvatarStyle = {
    backgroundColor: '#ff8420',
    border: '1px solid #7e7e7e',
    borderRadius: '50%',
    color: '#155a7e'
  };

  public statusFilterCtrl: FormControl = new FormControl();
  public filteredStatus: ReplaySubject<ContactStatus[]> = new ReplaySubject<ContactStatus[]>(1);
  private _unsubscribeAll: Subject<any>;

  public filteredAvatar: ReplaySubject<AvatarType[]> = new ReplaySubject<AvatarType[]>(1);
  public avatarFilterCtrl: FormControl = new FormControl();
  /**
   * Constructor
   *
   * @param _companyService
   * @param {FormBuilder} _formBuilder
   * @param {Location} _location
   * @param {MatSnackBar} _matSnackBar
   */
  constructor(
      private _companyService: CompanyService,
      private _formBuilder: FormBuilder,
      private _location: Location,
      private _matSnackBar: MatSnackBar
  )
  {
    // Set the default
    this.company = new Company();

    // Set the private defaults
    this._unsubscribeAll = new Subject();
    this.filteredStatus.next(this.statusOptions.slice());
    this.filteredAvatar.next(this.avatarOptions.slice());
    this.showOnAvatar = this.avatarOptions[2].value;
    this.showOnRelOther = false;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void
  {

    this.statusFilterCtrl.valueChanges
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(() => {
          this._filterStatus();
        });
    this.avatarFilterCtrl.valueChanges
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(() => {
          this._filterAvatarTypes();
        });

    // Subscribe to update product on changes
    this._companyService.onContactChanged
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(org => {
          console.log(org)
          if ( !_.isEmpty(org) && org.id !== null ) {
            this.company = new Company(org);
            this.company['base64Img'] = org.avatar.length > 0 ? org.avatar : '';
            this.base64Img = this.company['base64Img'];
            this.pageType = 'edit';
            this.companyForm = this.createCompanyForm();
            this.pbForm = this.createPBForm();
            this.showOnAvatar = this.company.avatarType;

          } else {
            this.pageType = 'new';
            const companyDefault = org;
            this.company = new Company();
            this.company.licence = companyDefault.licence;

            this.company['base64Img'] = null;
            this.companyForm = this.createCompanyForm();
            this.pbForm = this.createPBForm();
            this.companyForm.controls['status'].setValue( this.statusOptions[1].value);
            this.companyForm.controls['avatarType'].setValue( this.avatarOptions[2].value);
            this.showOnRelOther = false;
          }

        });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void
  {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  onChangeAvatarType($event) {
    this.showOnAvatar = $event.value;
  }

  uploadError($event) {
    this._matSnackBar.open($event, 'OK', {
      verticalPosition: 'top',
      duration        : 2000
    });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Create product form
   *
   * @returns {FormGroup}
   */
  createCompanyForm(): FormGroup
  {
    return this._formBuilder.group({
      id                  : [this.company.id],
      contactName         : [this.company.contactName, Validators.required],
      email               : [this.company.email, [Validators.required, Validators.email]],
      phone               : [this.company.phone],
      contactSince        : [this.company.contactSince],
      avatarType          : [this.company.avatarType],
      status              : [this.company.status],
      _version            : [this.company._version]
    });
  }

  createPBForm(): FormGroup
  {
    return this._formBuilder.group({
      id         : [this.company.id],
      notes      : [this.company.notes],
      _version   : [this.company._version]
    });
  }

  /**
   * Save product
   */
  saveCompany(): void {
    const data = this.companyForm.getRawValue();
    data.avatar = this.base64Img || 'assets/images/avatars/profile.jpg';
    const phoneNumber = parsePhoneNumberFromString(data.phone);
    if (!phoneNumber || !phoneNumber.isValid()) {
      this.eventMesssage('A Valid Phone Number is Required!', 'error');
      return;
    }
    this._companyService.save(data)
        .then((res) => {
          this._matSnackBar.open('Contact Saved Sucessfully', 'OK', {
            verticalPosition: 'top',
            duration        : 2000
          });
        }).catch(err => {
      this._matSnackBar.open('OOPS! Something is wrong .. Please try Again.', 'OK', {
        verticalPosition: 'top',
        duration        : 2000
      });
      console.log(err.errors[0].message);
    });
  }

  /**
   * Save product
   */
  saveCompanyOther(): void {
    if (this.pageType !== 'edit') {
      return;
    }
    const data = this.pbForm.getRawValue();
    data['id'] = this.company.id;
    this._companyService.savePB(data)
        .then((res) => {
          // Show the success message
          this._matSnackBar.open('Company Saved Sucessfully!', 'OK', {
            verticalPosition: 'top',
            duration        : 2000
          });
        }).catch(err => {
          this._matSnackBar.open('OOPS! Something is wrong .. Please try Again.', 'OK', {
            verticalPosition: 'top',
            duration        : 2000
          });
          console.log(err.errors[0].message);
        });
  }

  /**
   * Add product
   */
  addCompany(): void {
    const data = this.companyForm.getRawValue();
    data.avatar = this.base64Img || 'assets/images/contacts/company-default.png';
    const phoneNumber = parsePhoneNumberFromString(data.phone);
    if (!phoneNumber || !phoneNumber.isValid()) {
      this.eventMesssage('A Valid Phone Number is Required!', 'error');
      return;
    }
    this._companyService.add(data)
        .then(res => {
          // Show the success message
          this._matSnackBar.open('Company added succesfully', 'OK', {
            verticalPosition: 'top',
            duration        : 2000
          });
        }).catch(err => {
          this._matSnackBar.open('OOPS! Something is wrong .. Please try Again.', 'OK', {
            verticalPosition: 'top',
            duration        : 2000
          });
        console.log(err.errors[0].message);
    });
  }


  onReaderEnd($event) {
    this.base64Img = $event;
  }

  onSelectedFilesChanged($event) {
    const fileIn = $event[0];
    if (!fileIn) {
      return;
    }
    this.base64Img = '';
  }


  private _filterStatus() {
    if (!this.statusOptions) {
      return;
    }
    // get the search keyword
    let search = this.statusFilterCtrl.value;
    if (!search) {
      this.filteredStatus.next(this.statusOptions.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredStatus.next(
        this.statusOptions.filter(lang => lang.name.toLowerCase().indexOf(search) > -1)
    );
  }


  private _filterAvatarTypes() {
    if (!this.avatarOptions) {
      return;
    }
    // get the search keyword
    let search = this.avatarFilterCtrl.value;
    if (!search) {
      this.filteredAvatar.next(this.avatarOptions.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredAvatar.next(
        this.avatarOptions.filter(avatar => avatar.name.toLowerCase().indexOf(search) > -1)
    );
  }

  private eventMesssage( msg: string, typeMsg: string ) {
    const customMsg = typeMsg === 'error' ? 'OOPS:' + msg : 'Success!!' + msg;
    this._matSnackBar.open(customMsg, 'OK', {
      verticalPosition: 'top',
      duration        : 2000
    });
  }
}
